<template>
  <div>
    <v-footer
      padless
      class="footer"
      absolute
      color="transparent"
    >
      <v-card
        flat
        tile
        width="100%"
        class="text-center"
        color="transparent"
      >
        
        <v-card-text>
          {{ new Date().getFullYear() }} — <strong>MaLIS-Lab</strong>
          <br/>
          <v-btn
            class="mx-4"
            icon
            @click="snackbar=true;copiarPortapapeles()"
          >
            <v-icon size="16px">
              {{gmail}}
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-4"
            icon
            @click="redirect()"
          >
            <v-icon size="16px">
              {{ twitter }}
            </v-icon>
          </v-btn>

          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            class="mx-auto"
          >
            Copied on clipboard!
          </v-snackbar>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
  export default {
    data: () => ({
      twitter:'mdi-twitter',
      gmail:'mdi-gmail',
      snackbar:false,
      timeout:500,
    }),
    methods:{
      redirect(){
        window.location.href="https://twitter.com/MaLIS_Lab"
      },
      copiarPortapapeles(){
        navigator.clipboard.writeText("malis.lab@informatica.usach.cl");
        return 0;
      }
    }
  }
</script>

<style scoped>
.footer{
  position: absolute;
  width: 100%;
}
</style>