import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Malis',
    component: () => import('../components/Malis.vue')
  },
  {
    path: '/ResearchAreas',
    name: 'Research',
    component: () => import('../components/ResearchAreas.vue')
  },
  {
    path: '/Team',
    name: 'Team',
    component: () => import('../components/Team.vue')
  },
  {
    path: '/Publications',
    name: 'Publications',
    component: () => import('../components/Publications.vue')
  },
  {
    path: '/OpenDataDiinf',
    name: 'OpenData',
    component: () => import('../components/OpenDataDiinf.vue')
  },
  {
    path: '/Projects',
    name: 'Projects',
    component: () => import('../components/Projects.vue')
  },
  // Rutas Dinamicas
  {
    path: '/Team/:member',
    name: 'Member',
    component: () => import('../components/Member.vue'),
    props: true
  },
  {
    path: '/ResearchAreas/:area',
    name: 'Area',
    component: () => import('../components/SpecificArea.vue'),
    props: true
  },
  {
    path: '/OpenDataDiinf/:dataset',
    name: 'Dataset',
    component: () => import('../components/Dataset.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
