<template>
  <div>
  <v-app-bar
      color="transparent"
      dark
      dense
      prominent
      height="80px"
    >
      
      <v-img
        class="logo-diinf"
        src="../assets/Logos/diinf.svg"
        height="90%"
        contain
      >
      </v-img>

        <v-app-bar-title class="titleBar">Machine Learning for Image & Signal Processing Lab <br/> (MaLIS-Lab)</v-app-bar-title>

      <v-img
        class="logo-malis"
        src="../assets/Logos/malis.svg"
        height="120%"
        contain
      >
      </v-img>

      <template v-slot:extension>
        <v-card
          class="mx-auto"
          width="65%"
          flat
          color="transparent"
        >
          <v-tabs
            background-color="transparent"
            centered
            center-active
            slider-size = "3"
            show-arrows
          >
            <v-tabs-slider color="#efdd5a"></v-tabs-slider>

            <v-tab @click="cargarComp(1);">MaLIS-Lab</v-tab>
            <v-tab @click="cargarComp(2);">Research Areas</v-tab>
            <v-tab @click="cargarComp(3);">Team</v-tab>
            <!--<v-tab @click="cargarComp(4);">Recent News</v-tab>-->
            <v-tab @click="cargarComp(7);">Projects</v-tab>
            <v-tab @click="cargarComp(5);">Publications</v-tab>
            <v-tab @click="cargarComp(6);">Open Data Diinf</v-tab>
            <!--<v-tab @click="cargarComp(7);">Memoristas</v-tab>-->
          </v-tabs>
        </v-card>
      </template>
    </v-app-bar>
  </div>  
</template>

<script>

export default {
  name:"AppBar",
  methods: {
    cargarComp(componente){
      switch(componente){

        case 1:
          this.$router.push('/')
        break;

        case 2:
          this.$router.push('/ResearchAreas')
        break;

        case 3:
          this.$router.push('/Team')
        break;

        case 4:
          this.$router.push('/RecentNews')
        break;

        case 5:
          this.$router.push('/Publications')
        break;

        case 6:
          this.$router.push('/OpenDataDiinf')
        break;

        case 7:
          this.$router.push('/Projects')
        break;
      }
    },
  },
}


</script>

<style scoped>
  .logo-malis{
    margin: 8px 0 0 0;
    position: absolute;
    right: 0;
  }
  .logo-diinf{
    margin: 24px 0 0 0;
    position: absolute;
    left: 0;
  }
  .titleBar{
    z-index: 10;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    animation: name3 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s forwards;
    opacity: 0;
  }
  @keyframes name3 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  
</style>