<template>
  <v-app>
    <div id="app">
      <AppBar/>
        <div>
          <router-view/>
          <backTop/>
        </div>
      <Footer/>
    </div>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar"
import Footer from "./components/Footer"
import backTop from './components/GoTop.vue'

export default {
  name: "App",
  data () {
    return {
      nombre:''
    }
  },
  components:{
    Footer,
    AppBar,
    backTop,
  }
}


</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url("./assets/Fondos/fondo.svg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
